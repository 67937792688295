import api from "@/core/api";

export const second = (id: string) => api.query(`/pgi_research/second/${id}`, { method: "get" });
export const barcodeStatus = (barcode: string, status: number) => api.query(`/pgi_research/barcodestatus/${barcode}/${status}`, { method: "get" }, false);
export const setStatus = (id: string, params: any) => api.query(`/pgi_research/setstatus/${id}`, { data: params, method: "post" }, false);
export const lastBiopsyNmb = (data: any) => api.query(`/pgi_research/lastbiopsynmb`, { data, method: "post" }, false);
export const lastIGHNmb = (data: any) => api.query(`/pgi_research/lastighnmb`, { data, method: "post" }, false);
export const getResearch = (researchs: string) => api.query(`/pgi_research/getresearch`, { data: researchs, method: "post" });
export const sendMessageConclusion = (id: any) => api.query(`/pgi_research/sendmessageconclusion/${id}`);
export const researchInfo = (params: any) => api.query(`/pgi_research/researchinfo`, { data: params, method: "post" });
export const statistics = () => api.query(`/pgi_research/statistics`);
export const exportTable = (params: any = {}) => api.query(`/pgi_research/export`, { data: params, method: "post", responseType: 'blob' });
export const beforeSave = (data: any) => api.query(`/pgi_research/beforesave`, { method: "post", data });
export const checkBeforeCreating = (params: any) => api.query(`/pgi_research/checkbeforecreating`, { data: params, method: "post" });
export const researchesMailing = (researches: any) => api.query(`/pgi_research/researchesmailing`, { data: researches, method: "post" });
