import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "light-thead" }
const _hoisted_2 = {
  key: 0,
  style: {"width":"35px","text-align":"center"}
}
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("thead", _hoisted_1, [
    _createElementVNode("tr", null, [
      (_ctx.config.numbering)
        ? (_openBlock(), _createElementBlock("th", _hoisted_2, "№"))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.fields, (field, fieldkey) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: fieldkey }, [
          (_ctx.visible(field))
            ? (_openBlock(), _createElementBlock("th", {
                key: 0,
                innerHTML: field.description
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ])
  ]))
}