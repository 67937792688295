import { defineAsyncComponent } from 'vue';

export default {
	forms: {
		edit: {
			panel: {
				width: '70%'
			},
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		list: {
			component: defineAsyncComponent(() => import('./forms/List.vue'))
		},
		select: {
		}
	}
}