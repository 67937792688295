import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"width":"35px","text-align":"center"}
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tbody", null, [
    (_ctx.store.data.rows.length > 0)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.store.data.rows, (row, key) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: row.id
          }, [
            (_ctx.config.numbering)
              ? (_openBlock(), _createElementBlock("th", _hoisted_1, _toDisplayString(key + 1), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.fields, (field, fieldkey) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: fieldkey }, [
                (_ctx.visible(field))
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (field.config.width)
                        ? (_openBlock(), _createElementBlock("td", _hoisted_2, [
                            _createElementVNode("div", {
                              class: "cutoff",
                              style: _normalizeStyle({ width: field.config.width }),
                              innerHTML: _ctx.getData(field, fieldkey, row)
                            }, null, 12, _hoisted_3)
                          ]))
                        : (_openBlock(), _createElementBlock("td", {
                            key: 1,
                            innerHTML: _ctx.getData(field, fieldkey, row)
                          }, null, 8, _hoisted_4))
                    ], 64))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ]))
        }), 128))
      : (_openBlock(), _createElementBlock("tr", _hoisted_5, [
          _createElementVNode("td", {
            colspan: Object.keys(_ctx.store.state.fields).length + (_ctx.config.menu ? 1 : 0) + (_ctx.config.numbering ? 1 : 0)
          }, " ", 8, _hoisted_6)
        ]))
  ]))
}