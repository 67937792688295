import DBStore from "@/core/db_store";
import { deepMerge, genGUID } from "@/helpers/utils";

export default class DBTableController {
	public store: DBStore;
	public form: any;
	public field: any;
	public stateField: any;
	public settings: any;

	constructor(protected props: any) {
		this.form = props.form;
		this.field = props.field;

		this.settings = {
			numbering: false,
			...props.config
		}

		this.store = this.createStore();
	}

	getFieldModel(fields: any, structure: any): any {
		const field: any = fields.shift();

		if (fields.length > 0) {
			return this.getFieldModel(fields, structure.fields[field].model);
		} else {
			return structure.fields[field];
		}
	}

	createStore() {
		// обновлять позиции
		const field = deepMerge({}, this.getFieldModel(this.field.split('.'), this.form.store.state));

		const store = new DBStore(field?.table ? field.table : `table_${this.field}`)

		store.model = field.type.table;
		store.model.subtable = true;

		this.stateField = this.getFieldModel(this.field.split('.'), this.form.store.state);

		const fields: any = {};

		for (const field in this.settings.fields) {
			fields[field] = this.stateField.fields[field];
		}

		store.createState({
			...this.stateField,
			fields
		});

		const access = this.form.store.model.access ? this.form.store.model.access : {
			read: true,
			create: true,
			update: true,
			delete: true
		};

		const editable = 'access' in this.stateField ? store.availabeRole(this.stateField.access, this.field, field) : access.create || access.update || access.delete;

		store.model.access = {
			read: true,
			create: editable,
			update: editable,
			delete: editable
		}

		if (field.depends) {
			store.model.depends = field.depends;

			store.owner = this.form.store.getValue(field.depends);

			if (!store.owner) {
				store.owner = this.form.store.setValue(field.depends, genGUID());
			}
		}

		const data = this.form.store.getValue(this.field);

		if (data) {
			store.data.rows = data;
			store.data.position = data.length ? 0 : -1;
		} else {
			const newData = this.form.store.setValue(this.field, []);

			store.data.rows = newData;
			store.data.position = newData.length ? 0 : -1;
		}

		store.files = this.form.store.files;

		return store;
	}
}