<template>
	<thead class="light-thead">
		<tr>
			<th v-if="config.numbering" style="width: 35px; text-align: center;">№</th>
			<template v-for="(field, fieldkey) of store.state.fields" :key="fieldkey">
				<template v-if="visible(field)">
					<th v-html="field.description"></th>
				</template>
			</template>
		</tr>
	</thead>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	props: {
		store: {
			type: Object,
			default: () => ({})
		},
		config: {
			type: Object,
			default: () => ({})
		}
	},

	setup() {
		const visible = (field: any) => {
			const config = field?.config;

			return (typeof config.visible == 'function' ? config.visible() : config.visible) && !config.hide;
		}

		return {
			visible
		}
	}
})
</script>

<style></style>