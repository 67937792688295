<template>
	<div class="table-responsive mb-1">
		<table class="table table-striped table-bordered table-hover table-sm unselectable">
			<GridHead :store="controller.store" :config="controller.settings" />
			<GridBody :store="controller.store" :config="controller.settings" />
		</table>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import DBTableController from './controller';
import GridHead from "./GridHead";
import GridBody from "./GridBody";

export default defineComponent({
	components: {
		GridHead, GridBody
	},

	props: {
		config: {
			type: Object,
			default: () => ({})
		},
		form: {
			type: Object,
			default: null
		},
		field: {
			type: String,
			default: null
		}
	},

	setup(props) {
		const controller = new DBTableController(props);

		if (!controller.store) {
			console.error('Grid storage not installed');

			return {
				access: { read: false }
			}
		}

		return {
			controller
		}
	}
})
</script>

<style></style>