import { createStore } from 'vuex'

import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import api from '@/core/api'

const state: any = {
	token: api.token.get(),
	user: {
		id: null,
		roles: [],
		name: null,
		employee: null,
		_employee: null,
		organization: null
	},
	timeout: 0,
	messages: [],
	equipment: [],
	online: false,
	onlineAgent: false,
	load: false,
	locale: 'ru',
	loaded: false,
	openDocument: true,
	constants: {
		version: '0.0.0',
		files: {
			maxSize: 0
		},
		license: '',
		jitsiMeet: {
			domain: ''
		},
		accounting: {}
	}
}

export default createStore({
	state,
	mutations,
	actions,
	getters
})
