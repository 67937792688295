import { defineAsyncComponent } from 'vue';

export default {
	forms: {
		edit: {
			panel: {
				width: '70%'
			},
			config: {
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					name: {
					},
					user: {
					},
					type_equipment: {
					},
					close: {
					},
					settings: {
					}
				}
			},
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		list: {
			config: {
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					name: {
					},
					user: {
					},
					type_equipment: {
					},
					close: {
					},
					settings: {
						config: {
							visible: false
						}
					}
				}
			},
			component: defineAsyncComponent(() => import('./forms/List.vue'))
		}
	}
}