<template>
	<tbody>
		<template v-if="store.data.rows.length > 0">
			<template v-for="(row, key) of store.data.rows" :key="row.id">
				<tr>
					<th v-if="config.numbering" style="width: 35px; text-align: center;">{{ key + 1 }}</th>

					<template v-for="(field, fieldkey) of store.state.fields" :key="fieldkey">
						<template v-if="visible(field)">
							<template v-if="field.config.width">
								<td>
									<div class="cutoff" :style="{ width: field.config.width }" v-html="getData(field, fieldkey, row)"></div>
								</td>
							</template>
							<template v-else>
								<td v-html="getData(field, fieldkey, row)"></td>
							</template>
						</template>
					</template>
				</tr>
			</template>
		</template>
		<template v-else>
			<tr>
				<td :colspan="Object.keys(store.state.fields).length + (config.menu ? 1 : 0) + (config.numbering ? 1 : 0)">&nbsp;</td>
			</tr>
		</template>
	</tbody>
</template>

<script lang="tsx">
import dayjs from "dayjs";
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		store: {
			type: Object,
			default: () => ({})
		},
		config: {
			type: Object,
			default: () => ({})
		}
	},

	setup(props: any) {
		const getData = (structure: any, field: any, data: any) => {
			const calc = structure?.config?.calc;

			if (calc) return typeof calc == 'function' ? calc(data) : calc;

			let value = data[field];

			if (data[`_${field}`]) {
				value = data[`_${field}`];
			} else {
				if (typeof structure.type == 'object') {
					if (structure.type.table) {
						value = value ? `Objects [${value.length}]` : null;
					} else if (structure.type.fields) {
						const field_structure = props.store.model.fields[data[field]];

						value = field_structure ? getData(field_structure, data[field], data) : null;
					}
				} else {
					switch (structure.type) {
						case 'DECIMAL':
							if (structure?.config?.precision) {
								value = Number(value && !isNaN(value) ? value : 0).toFixed(structure.config.precision);
							} else {
								value = Number(value && !isNaN(value) ? value : 0);
							}

							break;
						case 'DATEONLY':
							if (data[field] && data[field] != '0000-00-00') value = dayjs(data[field]).format('DD.MM.YYYY');

							break;
						case 'TIME':
							if (data[field]) value = dayjs(data[field]).format('HH:mm:ss');

							break;
						case 'DATE':
							if (data[field]) value = dayjs(data[field]).format('DD.MM.YYYY, HH:mm:ss');

							break;
						case 'BOOLEAN':
							value = data[field] ? '<i class="icon icon-check"></i>' : '';

							break;
					}
				}
			}

			return value;
		}

		const visible = (field: any) => {
			const config = field?.config;

			return (typeof config.visible == 'function' ? config.visible() : config.visible) && !config.hide;
		}

		return {
			visible,
			getData
		}
	}
})
</script>

<style></style>
